@import "../../config/styles/theme.less";

.create-trail-main {

  .export-workorder-icon {
    color: #DDDDDD !important;
    background: #FFFFFF !important;
    border: none !important;
  }

  .protocol-name {
    cursor: pointer;
    color: var(--Blue-blue-50, #0092E4);

    &:hover {
      text-decoration: underline;
    }
  }

  .action-btn{
    button {
      outline: 0;
      border: 0;
      background-color: transparent;
      cursor: pointer;
  
      &:hover {
        background-color: transparent;
      }
    }
  }
  

  .head-sec {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 600;
    }

    button {
      padding: 10px 16px;
      display: flex;
      gap: 4px;
      align-items: center;
      background-color: @--green-green-60;
    }
  }

  .body-content {
    height: calc(100vh - 112px);
    background-color: white;
    width: 100%;
    padding: 24px;
    padding-bottom: 0 !important;
    overflow-x: hidden;
    position: relative;

    .search-sec {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        font-size: 15px;
      }

      .search-right {
        display: flex;
        align-items: center;
        gap: 10px;

        button {
          height: 31px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: @--neutral-neutral-10;
          border-radius: 4px;
          color: @--neutral-neutral-60;
        }
      }
    }

    .ant-table {
      height: calc(100vh - 249px);
      overflow-x: hidden;
      overflow-y: auto;
    }

    .ant-table-pagination.ant-pagination {
      margin: 0;
      padding: 16px 0;
      border-top: 1px solid var(--Neutral-neutral-20, #D9DBE9);
    }

    .table-sec {
      margin-top: 16px;

      table {
        tr {
          th {
            padding: 10px 16px !important;
          }
        }
      }

      .action-btn {
        display: flex;
        align-items: center;
        gap: 5px;

        button {
          color: @--neutral-neutral-40;
          font-size: 16px;
        }

        .del-btn {
          color: @--red-red-60;
        }
      }
    }
  }
}

.global-pagination {

  .ant-pagination-item,
  .ant-pagination-item-link {
    border: 1px solid @--neutral-neutral-10 !important;
  }

  .ant-pagination-item-active {
    border: 1px solid @--blue-blue-40;
  }

  .ant-pagination-total-text {
    order: -1;
  }

  .ant-pagination-prev {
    margin-left: auto;
  }

  .ant-pagination-options {
    order: -2;
    margin-left: 0;
    margin-right: 16px;
  }
}
@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;