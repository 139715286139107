.protocol-drawer-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;

  h3 {
    color: var(--Neutral-neutral-100, #14151C);
    font-family: "Noto Sans";
    font-size: 16px;
    margin: 0;
    font-weight: 600;
    letter-spacing: -0.12px;
  }

  h4 {
    color: var(--Neutral-neutral-60, #696F88);
    font-family: "Noto Sans";
    font-size: 12px;
    margin:0;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.09px;
  }

  p {
    flex: 1;
    color: var(--Neutral-neutral-100, #14151C);
    margin:0;
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.105px;
  }

  .text-center {
    justify-content: center;
  }

  .info-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .show-less-button {
    // width: 175px;
    border: none;
    background: transparent;
    text-align: right;
    color: var(--Blue-blue-60, #0071CD);
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    cursor: pointer;
    font-weight: 400;
    padding: 2px 8px;
    letter-spacing: -0.105px;

    &:hover {
      text-decoration: underline;
    }

    &:disabled {
      color: gray;
      cursor: not-allowed;
    }
  }

  .protocol-basic-info {
    display: flex;
    gap: 16px;
    align-items: flex-start;

    .basic-info-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      padding-right: 16px;
      border-right: 1px solid #DFE2E7;

      &:last-child {
        border-right: none;
      }

      .info-label {
        color: var(--Neutral-neutral-60, #696F88);
        font-family: "Noto Sans";
        font-size: 12px;
        font-weight: 600;
        letter-spacing: -0.09px;
      }

      .info-value {
        display: flex;
        gap: 4px;
        align-items: center;
        color: var(--Neutral-neutral-100, #14151C);
        font-family: "Noto Sans";
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.105px;
      }
    }
  }

  .basicinfo-des {
    border-bottom: 1px solid #DFE2E7;
    padding-bottom: 16px ;
  }

  .protocolo-objectives {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .objective-content {
      display: flex;
      gap: 8px;

      .obj-target-info {
        width: 120px;
        padding-right: 8px;
      }

      .obj-description-info {
        flex: 1 0 0;
      }
    }
  }

  .protocol-product-info {
    .ant-tabs {
      .ant-tabs-tab {
        font-size: 16px !important;
        font-weight: 600 !important;
        letter-spacing: -0.12px;

        .ant-tabs-tab-btn {
          display: inline-flex;
        }
      }
    }
    .ant-tabs-tab-btn{
      span{
        font-family: Noto Sans;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.75%;
        vertical-align: middle;
      }
    }
    .ant-tabs-tab-active{
      span{
        font-family: Noto Sans;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.75%;
        vertical-align: middle;
      }
    }
    .product-info {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .product-header {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        background: #F3F4F6;
      }

      .product-content {
        display: flex;
        gap: 16px;
        padding: 16px;
        border-bottom: 1px solid #DFE2E7;

        .product-image {
          width: 120px;
          height: 120px;
          background: #F3F4F6;
          border-radius: 8px;
        }

        .product-details {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 8px;

          .product-name {
            color: var(--Neutral-neutral-100, #14151C);
            font-family: "Noto Sans";
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.12px;
          }

          .product-description {
            color: var(--Neutral-neutral-100, #14151C);
            font-family: "Noto Sans";
            font-size: 14px;
            font-weight: 400;
            letter-spacing: -0.105px;
          }
        }
      }
}
    .product-seeds {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .ant-row {
        border-bottom: 1px solid #DFE2E7;
      }

      .ant-col {
        padding: 10px 16px;
      }

      .seed-table-header{
        background: #F3F4F6;
      }
    }
  }
}


@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;