.archive-button {
  padding: 0;
  height: auto;
  &:hover {
    background: transparent;
  }
  img {
    transition: opacity 0.2s;
  }
}
.warning-icon-container {
  border: 2px solid #ff4d4f;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
.ant-modal .ant-btn-dangerous {
  background-color: #CF3537;
  border-color: #CF3537;
  &:hover {
    background-color: #CF3537 !important;
    border-color: #CF3537 !important;
  }
}

.ant-modal-body .ant-spin-nested-loading,
.ant-modal-body .ant-spin-container {
  height: auto !important;
}
// Archive button loader
.ant-modal .ant-btn-loading-icon {
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
  .ant-spin {
    margin-top: -2px;
  }
}
// modal styles
.archive-modal-title{
  font-family: Noto Sans;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.archive-modal-description{
  font-family: Noto Sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.75%;
}
@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;