#subPlotDetailsCard {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 10;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  backdrop-filter: blur(10px);
  max-height: 74vh;
  overflow-y: auto;

  .details-container {
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    background-color: rgba(255, 255, 255, 0.4);
  }

  .plot-details {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;

    h3 {
      color: white;
      font-size: 0.875rem;
      font-weight: 600;
      background-color: #696f88;
      padding: 0.25rem 0.5rem;
      border-radius: 0.375rem;
    }
  }

  .detail-section {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h4 {
      color: white;
      font-size: 0.875rem;
      font-weight: 600;
      padding: 0.25rem 0.5rem;
      border-radius: 0.375rem;
    }

    &.seeds {
      h4 {
        background-color: #0c612c;
      }

      .detail-item {
        background-color: #c3ead1;

        .label,
        .value {
          color: #0c612c;
        }
      }
    }

    &.biologicals {
      h4 {
        background-color: #c17e19;
      }

      .detail-item {
        background-color: #ffe4ae;

        .label,
        .value {
          color: #744a0b;
        }
      }
    }

    &.fertilisers {
      h4 {
        background-color: #643aaa;
      }

      .detail-item {
        background-color: #e7ddfc;

        .label,
        .value {
          color: #462779;
        }
      }
    }
  }

  .detail-item {
    background-color: #f3f4f6;
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;

    .label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #696f88;
    }

    .value {
      color: #696f88;
      font-weight: bold;
    }
  }
}
@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;